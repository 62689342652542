@import "helper/font-size";
@import "helper/height-width";
@import "helper/margin";
@import "helper/padding";
@import "helper/text-style";

.c-pointer {
  cursor: pointer;
}

.space-filler {
  flex: 1 1 auto;
}

.new-line {
  word-wrap:break-word;
  white-space: pre-wrap;
}

@media print {
  .do-not-print, .do-not-print * {
    display: none !important;
  }

  #hubspot-messages-iframe-container {
    display: none!important;
    width: 0!important;
    height: 0!important;
  }

  .page-break{
    page-break-before: always;
  }

  body, html, #wrapper {
    width: 100%;
  }

  img {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

//@media not print {
.print-only, .print-only * {
  display: none !important;
}

//}

body, html {
  width: 100%;
  height: 100%;
}

.print-temporary-class {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: 100000;
}


.ql-editor {
  .mention {
    background-color: orange !important;
  }

}

.ql-mention-list-container{
  width: auto!important;
}
