sd-inventory-management-popup {

  .container {
    width: 100%;
    height: 100%;

    hr {
      border: none;
      height: 2px;
      color: darkgray;
      background-color: darkgray;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
    }

    .header {
      background-color: rgb(19, 48, 75);
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      right: 0;
      height: 56px;

      h2 {

        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .body {
      position: absolute;
      overflow-x: hidden;
      overflow-y: auto;
      top: 56px;
      left: 0;
      right: 0;
      bottom: 56px;

      .task-show-container {
        .content {
          padding-top: 20px;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 20px;

          .heading {
            &.matrix {
              max-width: 45%;
              min-width: 45%;
              width: 45%;
              padding-top:5px;
            }

            @media only screen and (max-width: 768px) {
              font-weight: bold;
              font-size:15px;
            }

            @media only screen and (min-width: 769px) {
              font-weight: bolder;
              font-size: 17px;
            }
          }

          .detail {
            @media only screen and (max-width: 768px) {
              font-weight: normal;
              font-size: 13px;
            }

            @media only screen and (min-width: 769px) {
              font-weight: normal;
              font-size: 15px;
            }
          }

          .expense-container {
            .title-detail-container {
              display: flex;
              justify-content: start;
              flex-direction: row;
              text-align: start;
              vertical-align: middle;

              .expense-title-description {
                @media only screen and (max-width: 768px) {
                  font-size: 20px;

                  mat-icon {
                    height: 20px;
                    width: 20px;
                  }
                }
              }
            }

            .price-heading {
              @media only screen and (max-width: 768px) {
                font-weight: bold;
              }

              @media only screen and (min-width: 769px) {
                font-weight: bolder;
                font-size: 17px;
              }
            }

            .expense-print-content {
              font-size: 12px;
              font-weight: lighter;
            }
          }

          .task-notes-container {
            font-family: 'Roboto', sans-serif;
            white-space: pre-line;
            word-wrap: break-word;
            text-overflow: ellipsis;
          }

        }
      }

      .task-form-container {

        .edit-expense-container {
          mat-form-field {
            width: 95%;
          }
        }
      }

      .textArea {
        width: 100%;
        min-height: 100px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-color: #194267;
        padding: 10px;
        border-style: solid;
        margin: 2px;
        border-width: 1px;
        border-radius: 3px;
      }

      textarea {
        resize: none;
      }

    }

    .footer {
      position: absolute;
      overflow: hidden;
      height: 56px;
      left: 0;
      right: 0;
      bottom: 0;

      mat-toolbar > button {
        margin-left: 5px;
        margin-right: 5px;

        .complete-button {
          color: white !important;
          background-color: #288808 !important;
        }
      }
    }

    .uploaded-image-container {
      display: flex;
      align-items: center;
      padding: 2px 2px 2px 2px;

      .uploaded-image {
        width: 150px;
        height: 150px;
        margin: 2px 2px 2px 2px;
      }
    }
  }
}

