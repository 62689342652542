.center-text {
  text-align: center;
  vertical-align: middle;
}

.truncate-1l {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.truncate-2l {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-3l {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
