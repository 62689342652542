sd-task-show-create, sd-slide-show-popup {
  .custom-dialog-container{
    background: transparent;
  }

  @media print {
    .container {
      display: flex!important;
      flex-direction: column!important;
      height: auto!important;
      width: auto!important;
      padding: 0;
      max-width: unset!important;

      .body {
        position: unset!important;
        max-width: unset!important;
        overflow: auto!important;
        padding-left: 0;
        padding-right: 0;
      }

      .header {
        position: unset!important;
        width: 100%;
      }

    }
    img {
      display: block;
      page-break-before: always;
      page-break-inside: avoid;
    }
  }

  .container {
    width: 100%;
    height: 100%;

    hr {
      border: none;
      height: 2px;
      color: darkgray;
      background-color: darkgray;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
    }

    .header {
      background-color: rgb(19, 48, 75);
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      right: 0;
      height: 56px;

      h2 {

        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .body {
      padding-left: 15px;
      padding-right: 15px;
      position: absolute;
      overflow-x: hidden;
      overflow-y: auto;
      top: 56px;
      left: 0;
      right: 0;
      bottom: 56px;

      .task-show-container {
        .content {
          padding-top: 20px;
          padding-bottom: 20px;

          .largeFont{
            font-size:25px;
          }

          .actualContent {
            font-size: 12px;
            line-height: 130%;
            max-width: 1050px;
            text-align: justify;
            white-space: pre-line;
            text-overflow: ellipsis;
            word-wrap: break-word;
          }

          .heading {
            &.matrix {
              max-width: 45%;
              min-width: 45%;
              width: 45%;
            }

            @media only screen and (max-width: 768px) {
              font-weight: bold;
            }

            @media only screen and (min-width: 769px) {
              font-weight: bolder;
              font-size: 17px;
            }
          }

          .big-heading {
            font-size: 20px;
            font-weight: bold;
          }

          .detail {
            @media only screen and (max-width: 768px) {
              font-weight: normal;
              font-size: 13px;
            }

            @media only screen and (min-width: 769px) {
              font-weight: normal;
              font-size: 15px;
            }
          }

          .expense-container {
            .title-detail-container {
              display: flex;
              justify-content: start;
              flex-direction: row;
              text-align: start;
              vertical-align: middle;

              .expense-title-description {
                @media only screen and (max-width: 768px) {
                  font-size: 20px;

                  mat-icon {
                    height: 20px;
                    width: 20px;
                  }
                }
              }
            }

            .price-heading {
              @media only screen and (max-width: 768px) {
                font-weight: bold;
              }

              @media only screen and (min-width: 769px) {
                font-weight: bolder;
                font-size: 17px;
              }
            }

            .expense-print-content {
              font-size: 12px;
              font-weight: lighter;
            }
          }


          .task-notes-container {
            font-family: 'Roboto', sans-serif;
            white-space: pre-line;
            word-wrap: break-word;
            text-overflow: ellipsis;
          }

        }
      }

      .task-form-container {

        .edit-expense-container {
          mat-form-field {
            width: 95%;
          }
        }
      }

      .textArea {
        width: 100%;
        min-height: 100px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-color: #194267;
        padding: 10px;
        border-style: solid;
        margin: 2px;
        border-width: 1px;
        border-radius: 3px;
      }

      textarea {
        resize: none;
      }

    }

    .footer {
      position: absolute;
      overflow: hidden;
      height: 56px;
      left: 0;
      right: 0;
      bottom: 0;

      mat-toolbar > button {
        margin-left: 5px;
        margin-right: 5px;

        .complete-button {
          color: white !important;
          background-color: #288808 !important;
        }
      }
    }

    @media all and (max-width: 575px)  {

      .footer{
        overflow-x: scroll;
      }

    }

    .uploaded-image-container {
      display: flex;
      align-items: center;
      padding: 2px 2px 2px 2px;

      .uploaded-image {
        width: 150px;
        height: 150px;
        margin: 2px 2px 2px 2px;
      }
    }
  }
  .task-show-container,
  .task-form-container {
  }
  mat-grid-list {
    margin-top: 10px;
  }
  .background{
    background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
    border: 1px solid grey;
  }


  .highlight{
    background: lightgrey;
  }

  .upload-attachment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    img {
      max-height: 90%;
      max-width: 100%;
      object-fit: contain;
    }
    mat-progress-bar {
      width: 100%;
      position: absolute;
      bottom: 48px;
      left: 0;
    }
  }

  .attachments-container {
    .heading-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      .save-message {
        margin-left: 10px;
      }
    }
    .big-heading {
      font-size: 20px;
      font-weight: bold;
    }
    .grid-tile-container {

      .delete-button {
        visibility: hidden;
        float: left;
        z-index: 22;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        color: white;
        height: 36px;
      }

      .grid-content {
        position: absolute;
        left: 50%;
        top: 50%;
        right: 0;
        bottom: 0;
        display: block;
        text-align: center;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        transform: translate(-50%, -50%);
      }
      .limit-width {
        height: 80px;
        width: 80px;
      }
    }
  }

}
