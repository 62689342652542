/**
 * All Padding
 */
.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 50px;
}

.p-30 {
  padding: 50px;
}

.p-50 {
  padding: 50px;
}

/**
 * Top Padding
 */
.pt-5 {
  padding-top: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 50px;
}

.pt-30 {
  padding-top: 50px;
}

.pt-50 {
  padding-top: 50px;
}

/**
 * Right Padding
 */
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 50px;
}

.pr-30 {
  padding-right: 50px;
}

.pr-50 {
  padding-right: 50px;
}

/**
 * Bottom Padding
 */
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 50px;
}

.pb-30 {
  padding-bottom: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

/**
 * Left Padding
 */
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 50px;
}

.pl-30 {
  padding-left: 50px;
}

.pl-50 {
  padding-left: 50px;
}
