.fs-i {
  font-style: italic;
}

.fs-b {
  font-weight: bold;
}

.fs-i {
  text-decoration: underline;
}

.fs-10 {
  font-size: 10px;
}

.fs-15 {
  font-size: 15px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-10-i {
  font-size: 10px;
  font-style: italic;
}

.fs-15-i {
  font-size: 15px;
  font-style: italic;
}

.fs-20-i {
  font-size: 20px;
  font-style: italic;
}

.fs-25-i {
  font-size: 25px;
  font-style: italic;
}

.fs-10-u {
  font-size: 10px;
  text-decoration: underline;
}

.fs-15-u {
  font-size: 15px;
  text-decoration: underline;
}

.fs-20-u {
  font-size: 20px;
  text-decoration: underline;
}

.fs-25-u {
  font-size: 25px;
  text-decoration: underline;
}

.fs-10-b {
  font-size: 10px;
  font-weight: bold;
}

.fs-15-b {
  font-size: 15px;
  font-weight: bold;
}

.fs-20-b {
  font-size: 20px;
  font-weight: bold;
}

.fs-25-b {
  font-size: 25px;
  font-weight: bold;
}

.fs-10-2b {
  font-size: 10px;
  font-weight: bolder;
}

.fs-15-2b {
  font-size: 15px;
  font-weight: bolder;
}

.fs-20-2b {
  font-size: 20px;
  font-weight: bolder;
}

.fs-25-2b {
  font-size: 25px;
  font-weight: bolder;
}
