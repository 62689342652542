sd-task-show-create .custom-dialog-container, sd-slide-show-popup .custom-dialog-container {
  background: transparent;
}
@media print {
  sd-task-show-create .container, sd-slide-show-popup .container {
    display: flex !important;
    flex-direction: column !important;
    height: auto !important;
    width: auto !important;
    padding: 0;
    max-width: unset !important;
  }
  sd-task-show-create .container .body, sd-slide-show-popup .container .body {
    position: unset !important;
    max-width: unset !important;
    overflow: auto !important;
    padding-left: 0;
    padding-right: 0;
  }
  sd-task-show-create .container .header, sd-slide-show-popup .container .header {
    position: unset !important;
    width: 100%;
  }
  sd-task-show-create img, sd-slide-show-popup img {
    display: block;
    page-break-before: always;
    page-break-inside: avoid;
  }
}
sd-task-show-create .container, sd-slide-show-popup .container {
  width: 100%;
  height: 100%;
}
sd-task-show-create .container hr, sd-slide-show-popup .container hr {
  border: none;
  height: 2px;
  color: darkgray;
  background-color: darkgray;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
sd-task-show-create .container .header, sd-slide-show-popup .container .header {
  background-color: #13304b;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
}
@media only screen and (max-width: 768px) {
  sd-task-show-create .container .header h2, sd-slide-show-popup .container .header h2 {
    font-size: 14px;
  }
}
sd-task-show-create .container .body, sd-slide-show-popup .container .body {
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 56px;
}
sd-task-show-create .container .body .task-show-container .content, sd-slide-show-popup .container .body .task-show-container .content {
  padding-top: 20px;
  padding-bottom: 20px;
}
sd-task-show-create .container .body .task-show-container .content .largeFont, sd-slide-show-popup .container .body .task-show-container .content .largeFont {
  font-size: 25px;
}
sd-task-show-create .container .body .task-show-container .content .actualContent, sd-slide-show-popup .container .body .task-show-container .content .actualContent {
  font-size: 12px;
  line-height: 130%;
  max-width: 1050px;
  text-align: justify;
  white-space: pre-line;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
sd-task-show-create .container .body .task-show-container .content .heading.matrix, sd-slide-show-popup .container .body .task-show-container .content .heading.matrix {
  max-width: 45%;
  min-width: 45%;
  width: 45%;
}
@media only screen and (max-width: 768px) {
  sd-task-show-create .container .body .task-show-container .content .heading, sd-slide-show-popup .container .body .task-show-container .content .heading {
    font-weight: bold;
  }
}
@media only screen and (min-width: 769px) {
  sd-task-show-create .container .body .task-show-container .content .heading, sd-slide-show-popup .container .body .task-show-container .content .heading {
    font-weight: bolder;
    font-size: 17px;
  }
}
sd-task-show-create .container .body .task-show-container .content .big-heading, sd-slide-show-popup .container .body .task-show-container .content .big-heading {
  font-size: 20px;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  sd-task-show-create .container .body .task-show-container .content .detail, sd-slide-show-popup .container .body .task-show-container .content .detail {
    font-weight: normal;
    font-size: 13px;
  }
}
@media only screen and (min-width: 769px) {
  sd-task-show-create .container .body .task-show-container .content .detail, sd-slide-show-popup .container .body .task-show-container .content .detail {
    font-weight: normal;
    font-size: 15px;
  }
}
sd-task-show-create .container .body .task-show-container .content .expense-container .title-detail-container, sd-slide-show-popup .container .body .task-show-container .content .expense-container .title-detail-container {
  display: flex;
  justify-content: start;
  flex-direction: row;
  text-align: start;
  vertical-align: middle;
}
@media only screen and (max-width: 768px) {
  sd-task-show-create .container .body .task-show-container .content .expense-container .title-detail-container .expense-title-description, sd-slide-show-popup .container .body .task-show-container .content .expense-container .title-detail-container .expense-title-description {
    font-size: 20px;
  }
  sd-task-show-create .container .body .task-show-container .content .expense-container .title-detail-container .expense-title-description mat-icon, sd-slide-show-popup .container .body .task-show-container .content .expense-container .title-detail-container .expense-title-description mat-icon {
    height: 20px;
    width: 20px;
  }
}
@media only screen and (max-width: 768px) {
  sd-task-show-create .container .body .task-show-container .content .expense-container .price-heading, sd-slide-show-popup .container .body .task-show-container .content .expense-container .price-heading {
    font-weight: bold;
  }
}
@media only screen and (min-width: 769px) {
  sd-task-show-create .container .body .task-show-container .content .expense-container .price-heading, sd-slide-show-popup .container .body .task-show-container .content .expense-container .price-heading {
    font-weight: bolder;
    font-size: 17px;
  }
}
sd-task-show-create .container .body .task-show-container .content .expense-container .expense-print-content, sd-slide-show-popup .container .body .task-show-container .content .expense-container .expense-print-content {
  font-size: 12px;
  font-weight: lighter;
}
sd-task-show-create .container .body .task-show-container .content .task-notes-container, sd-slide-show-popup .container .body .task-show-container .content .task-notes-container {
  font-family: "Roboto", sans-serif;
  white-space: pre-line;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
sd-task-show-create .container .body .task-form-container .edit-expense-container mat-form-field, sd-slide-show-popup .container .body .task-form-container .edit-expense-container mat-form-field {
  width: 95%;
}
sd-task-show-create .container .body .textArea, sd-slide-show-popup .container .body .textArea {
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-color: #194267;
  padding: 10px;
  border-style: solid;
  margin: 2px;
  border-width: 1px;
  border-radius: 3px;
}
sd-task-show-create .container .body textarea, sd-slide-show-popup .container .body textarea {
  resize: none;
}
sd-task-show-create .container .footer, sd-slide-show-popup .container .footer {
  position: absolute;
  overflow: hidden;
  height: 56px;
  left: 0;
  right: 0;
  bottom: 0;
}
sd-task-show-create .container .footer mat-toolbar > button, sd-slide-show-popup .container .footer mat-toolbar > button {
  margin-left: 5px;
  margin-right: 5px;
}
sd-task-show-create .container .footer mat-toolbar > button .complete-button, sd-slide-show-popup .container .footer mat-toolbar > button .complete-button {
  color: white !important;
  background-color: #288808 !important;
}
@media all and (max-width: 575px) {
  sd-task-show-create .container .footer, sd-slide-show-popup .container .footer {
    overflow-x: scroll;
  }
}
sd-task-show-create .container .uploaded-image-container, sd-slide-show-popup .container .uploaded-image-container {
  display: flex;
  align-items: center;
  padding: 2px 2px 2px 2px;
}
sd-task-show-create .container .uploaded-image-container .uploaded-image, sd-slide-show-popup .container .uploaded-image-container .uploaded-image {
  width: 150px;
  height: 150px;
  margin: 2px 2px 2px 2px;
}
sd-task-show-create mat-grid-list, sd-slide-show-popup mat-grid-list {
  margin-top: 10px;
}
sd-task-show-create .background, sd-slide-show-popup .background {
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 100%);
  border: 1px solid grey;
}
sd-task-show-create .highlight, sd-slide-show-popup .highlight {
  background: lightgrey;
}
sd-task-show-create .upload-attachment-container, sd-slide-show-popup .upload-attachment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
sd-task-show-create .upload-attachment-container img, sd-slide-show-popup .upload-attachment-container img {
  max-height: 90%;
  max-width: 100%;
  object-fit: contain;
}
sd-task-show-create .upload-attachment-container mat-progress-bar, sd-slide-show-popup .upload-attachment-container mat-progress-bar {
  width: 100%;
  position: absolute;
  bottom: 48px;
  left: 0;
}
sd-task-show-create .attachments-container .heading-container, sd-slide-show-popup .attachments-container .heading-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
sd-task-show-create .attachments-container .heading-container .save-message, sd-slide-show-popup .attachments-container .heading-container .save-message {
  margin-left: 10px;
}
sd-task-show-create .attachments-container .big-heading, sd-slide-show-popup .attachments-container .big-heading {
  font-size: 20px;
  font-weight: bold;
}
sd-task-show-create .attachments-container .grid-tile-container .delete-button, sd-slide-show-popup .attachments-container .grid-tile-container .delete-button {
  visibility: hidden;
  float: left;
  z-index: 22;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: white;
  height: 36px;
}
sd-task-show-create .attachments-container .grid-tile-container .grid-content, sd-slide-show-popup .attachments-container .grid-tile-container .grid-content {
  position: absolute;
  left: 50%;
  top: 50%;
  right: 0;
  bottom: 0;
  display: block;
  text-align: center;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
}
sd-task-show-create .attachments-container .grid-tile-container .limit-width, sd-slide-show-popup .attachments-container .grid-tile-container .limit-width {
  height: 80px;
  width: 80px;
}

sd-tasks-list-component p, sd-tasks-list-component a, sd-tasks-list-component h1, sd-tasks-list-component h2, sd-tasks-list-component h3, sd-tasks-list-component span {
  font-family: "Lato", sans-serif;
}

sd-header .version-code {
  color: lightgray;
  font-style: italic;
}

sd-inventory-management-popup .container {
  width: 100%;
  height: 100%;
}
sd-inventory-management-popup .container hr {
  border: none;
  height: 2px;
  color: darkgray;
  background-color: darkgray;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
sd-inventory-management-popup .container .header {
  background-color: #13304b;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
}
@media only screen and (max-width: 768px) {
  sd-inventory-management-popup .container .header h2 {
    font-size: 14px;
  }
}
sd-inventory-management-popup .container .body {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 56px;
}
sd-inventory-management-popup .container .body .task-show-container .content {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}
sd-inventory-management-popup .container .body .task-show-container .content .heading.matrix {
  max-width: 45%;
  min-width: 45%;
  width: 45%;
  padding-top: 5px;
}
@media only screen and (max-width: 768px) {
  sd-inventory-management-popup .container .body .task-show-container .content .heading {
    font-weight: bold;
    font-size: 15px;
  }
}
@media only screen and (min-width: 769px) {
  sd-inventory-management-popup .container .body .task-show-container .content .heading {
    font-weight: bolder;
    font-size: 17px;
  }
}
@media only screen and (max-width: 768px) {
  sd-inventory-management-popup .container .body .task-show-container .content .detail {
    font-weight: normal;
    font-size: 13px;
  }
}
@media only screen and (min-width: 769px) {
  sd-inventory-management-popup .container .body .task-show-container .content .detail {
    font-weight: normal;
    font-size: 15px;
  }
}
sd-inventory-management-popup .container .body .task-show-container .content .expense-container .title-detail-container {
  display: flex;
  justify-content: start;
  flex-direction: row;
  text-align: start;
  vertical-align: middle;
}
@media only screen and (max-width: 768px) {
  sd-inventory-management-popup .container .body .task-show-container .content .expense-container .title-detail-container .expense-title-description {
    font-size: 20px;
  }
  sd-inventory-management-popup .container .body .task-show-container .content .expense-container .title-detail-container .expense-title-description mat-icon {
    height: 20px;
    width: 20px;
  }
}
@media only screen and (max-width: 768px) {
  sd-inventory-management-popup .container .body .task-show-container .content .expense-container .price-heading {
    font-weight: bold;
  }
}
@media only screen and (min-width: 769px) {
  sd-inventory-management-popup .container .body .task-show-container .content .expense-container .price-heading {
    font-weight: bolder;
    font-size: 17px;
  }
}
sd-inventory-management-popup .container .body .task-show-container .content .expense-container .expense-print-content {
  font-size: 12px;
  font-weight: lighter;
}
sd-inventory-management-popup .container .body .task-show-container .content .task-notes-container {
  font-family: "Roboto", sans-serif;
  white-space: pre-line;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
sd-inventory-management-popup .container .body .task-form-container .edit-expense-container mat-form-field {
  width: 95%;
}
sd-inventory-management-popup .container .body .textArea {
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-color: #194267;
  padding: 10px;
  border-style: solid;
  margin: 2px;
  border-width: 1px;
  border-radius: 3px;
}
sd-inventory-management-popup .container .body textarea {
  resize: none;
}
sd-inventory-management-popup .container .footer {
  position: absolute;
  overflow: hidden;
  height: 56px;
  left: 0;
  right: 0;
  bottom: 0;
}
sd-inventory-management-popup .container .footer mat-toolbar > button {
  margin-left: 5px;
  margin-right: 5px;
}
sd-inventory-management-popup .container .footer mat-toolbar > button .complete-button {
  color: white !important;
  background-color: #288808 !important;
}
sd-inventory-management-popup .container .uploaded-image-container {
  display: flex;
  align-items: center;
  padding: 2px 2px 2px 2px;
}
sd-inventory-management-popup .container .uploaded-image-container .uploaded-image {
  width: 150px;
  height: 150px;
  margin: 2px 2px 2px 2px;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

/*Calendar Related CSS*/
article.cal-container {
  width: 95%;
  border: solid 1px #ddd;
  display: flex;
  position: relative;
  overflow-x: hidden;
  box-sizing: border-box;
}

.duvet-places-name {
  box-sizing: border-box;
  width: 350px;
  padding-left: 20px;
  z-index: 1;
  box-shadow: 0 0 5px #999;
}

.duvet-material-calendar {
  box-sizing: border-box;
  overflow-x: scroll;
}

.duvet-material-calendar::-webkit-scrollbar {
  display: none;
}

.duvet-places-name aside {
  min-height: 38px;
  max-height: 46px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 50px;
}

.duvet-places-name aside:first-child {
  padding-top: 8px;
  border: none;
  max-height: 50px !important;
}

.duvet-places-name aside:nth-child(2) {
  border-top: solid 1px #ddd;
}

section {
  display: inline;
  max-width: 80%;
  /*max-height:;*/
  border-spacing: 0;
  color: #777;
}

main {
  display: flex;
  white-space: nowrap;
  position: relative;
  /*overflow-x: auto;*/
}

main > span {
  display: inline-flex;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  text-align: center;
  height: 21px;
  line-height: 21px;
}

main div {
  width: 100px;
  min-width: 100px;
  height: 38px;
  border: solid 1px #ddd;
  border-left: none;
  border-bottom: none;
  display: inline-flex;
  justify-content: flex-end;
}

main div span {
  padding-right: 3px;
  padding-bottom: 2px;
  position: absolute;
  font-size: 13px;
  bottom: 0;
}

.s-next, .s-prev {
  position: absolute;
  top: 0;
  display: inline-block;
  background-color: transparent;
  height: 38px;
  width: 20px;
  text-align: center;
  cursor: pointer;
  z-index: 3;
  line-height: 38px;
}

.s-next {
  right: 0;
}

.s-prev {
  left: 210px;
  transform: translateX(50%);
}