/**
 * Height in pixels
 */
.h-5 {
  height: 5px;
}

.h-10 {
  height: 10px;
}

.h-15 {
  height: 15px;
}

.h-20 {
  height: 20px;
}

.h-30 {
  height: 30px;
}

/**
 * Width in pixels
 */
.w-5 {
  width: 5px;
}

.w-10 {
  width: 10px;
}

.w-15 {
  width: 15px;
}

.w-20 {
  width: 20px;
}

.w-30 {
  width: 30px;
}

/**
 * Height && Width in pixels
 */
.hw-5 {
  height: 5px;
  width: 5px;
}

.hw-10 {
  height: 10px;
  width: 10px;
}

.hw-15 {
  height: 15px;
  width: 15px;
}

.hw-20 {
  height: 20px;
  width: 20px;
}

.hw-30 {
  height: 30px;
  width: 30px;
}

/**
 * Height in ratio
 */
.hr-5 {
  height: 5%;
}

.hr-10 {
  height: 10%;
}

.hr-15 {
  height: 15%;
}

.hr-20 {
  height: 20%;
}

.hr-25 {
  height: 25%;
}

.hr-50 {
  height: 50%;
}

.hr-75 {
  height: 75%;
}

.hr-100 {
  height: 100%;
}

/**
 * Width in ratio
 */
.wr-5 {
  width: 5%;
}

.wr-10 {
  width: 10%;
}

.wr-15 {
  width: 15%;
}

.wr-20 {
  width: 20%;
}

.wr-25 {
  width: 25%;
}

.wr-50 {
  width: 50%;
}

.wr-75 {
  width: 75%;
}

.wr-100 {
  width: 100%;
}

/**
 * Height && Width in ratio
 */
.hw-5 {
  height: 5px;
  width: 5px;
}

.hw-10 {
  height: 10px;
  width: 10px;
}

.hw-15 {
  height: 15px;
  width: 15px;
}

.hw-20 {
  height: 20px;
  width: 20px;
}

.hwr-5 {
  height: 5%;
  width: 5%;
}

.hwr-10 {
  height: 10%;
  width: 10%;
}

.hwr-15 {
  height: 15%;
  width: 15%;
}

.hwr-20 {
  height: 20%;
  width: 20%;
}

.hwr-25 {
  height: 25%;
  width: 25%;
}

.hwr-50 {
  height: 50%;
  width: 50%;
}

.hwr-75 {
  height: 75%;
  width: 75%;
}

.hwr-100 {
  height: 100%;
  width: 100%;
}
