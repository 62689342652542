body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

/*Calendar Related CSS*/
article.cal-container {
  width: 95%;
  border: solid 1px #ddd;
  display: flex;
  position: relative;
  overflow-x: hidden;
  box-sizing: border-box;
}

.duvet-places-name {
  box-sizing: border-box;
  width: 350px;
  padding-left: 20px;
  z-index: 1;
  box-shadow: 0 0 5px #999;
}

.duvet-material-calendar {
  box-sizing: border-box;
  overflow-x: scroll;
}

.duvet-material-calendar::-webkit-scrollbar {
  display: none;
}

.duvet-places-name aside {
  min-height: 38px;
  max-height: 46px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 50px;
}

.duvet-places-name aside:first-child {
  padding-top: 8px;
  border: none;
  max-height: 50px !important;
}
.duvet-places-name aside:nth-child(2) {
  border-top: solid 1px #ddd;
}

section {
  display: inline;
  max-width: 80%;
  /*max-height:;*/
  border-spacing: 0;
  color: #777;
}

main {
  display: flex;
  white-space: nowrap;
  position: relative;
  /*overflow-x: auto;*/
}

main > span {
  display: inline-flex;
  justify-content: center;
  width: 100px;
  min-width: 100px;
  text-align: center;
  height: 21px;
  line-height: 21px;
}

main div {
  width: 100px;
  min-width: 100px;
  height: 38px;
  border: solid 1px #ddd;
  border-left: none;
  border-bottom: none;
  display: inline-flex;
  justify-content: flex-end;
}

main div span {
  padding-right: 3px;
  padding-bottom: 2px;
  position: absolute;
  font-size: 13px;
  bottom: 0;
}

//.inspect-after:after, .dirty-after:after, .cleaned-after:after, .inactive-after:after {
//  content: "";
//  position: absolute;
//  height: 0;
//  width: 0;
//  border-top: solid 17px transparent;
//  border-left: solid 48px transparent;
//  opacity: 0.6;
//  transform: translateX(0) translateY(2px);
//}
//
//.inspect-before:before, .dirty-before:before, .cleaned-before:before, .inactive-before:before {
//  content: "";
//  position: absolute;
//  height: 0;
//  width: 0;
//  border-bottom: solid 17px transparent;
//  border-right: solid 48px transparent;
//  opacity: 0.6;
//  transform: translateX(-4px) translateY(2px);
//}
//
//.inspect-after:after {
//  border-right: solid 48px #ffca28;
//  border-bottom: solid 17px #ffca28;
//}
//
//.inspect-before:before {
//  border-left: solid 48px #ffca28;
//  border-top: solid 17px #ffca28;
//}
//
//.cleaned-after:after {
//  border-right: solid 48px rgba(76, 175, 80, 1);
//  border-bottom: solid 17px rgba(76, 175, 80, 1);
//}
//
//.cleaned-before:before {
//  border-left: solid 48px rgba(76, 175, 80, 1);
//  border-top: solid 17px rgba(76, 175, 80, 1);
//}
//
//.dirty-after:after {
//  border-right: solid 48px rgba(244, 67, 54, 1);
//  border-bottom: solid 17px rgba(244, 67, 54, 1);
//}
//
//.dirty-before:before {
//  border-left: solid 48px rgba(244, 67, 54, 1);
//  border-top: solid 17px rgba(244, 67, 54, 1);
//}
//
//.inactive-after:after {
//  border-right: solid 50px #e9e9e9;
//  border-bottom: solid 21px #e9e9e9;
//  transform: translateX(0) translateY(0) !important;
//}
//
//.inactive-before:before {
//  border-left: solid 52px #e9e9e9;
//  border-top: solid 21px #e9e9e9;
//  transform: translateX(0) translateY(0) !important;
//}
//
//.inactive-after > span:after, .inactive-before > span:after {
//  content: "";
//  position: absolute;
//  height: 5px;
//  width: 5px;
//  border-radius: 100%;
//  background-color: #777;
//  border: solid 3px #e9e9e9;
//  z-index: 4;
//  top: -21px;
//  left: -50px;
//}
//
//.inspect-after {
//  border-right-color: rgba(255, 202, 40, 0.5) !important;
//}
//
//.cleaned-after {
//  border-right-color: rgba(76, 175, 80, 0.5) !important;
//}
//
//.dirty-after {
//  border-right-color: rgba(244, 67, 54, 0.5) !important;
//}

.s-next, .s-prev {
  position: absolute;
  top: 0;
  display: inline-block;
  background-color: transparent;
  height: 38px;
  width: 20px;
  text-align: center;
  cursor: pointer;
  z-index: 3;
  line-height: 38px;
}

.s-next {
  right: 0
}

.s-prev {
  left: 210px;
  transform: translateX(50%);
}
