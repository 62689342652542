.fs-i {
  font-style: italic;
}

.fs-b {
  font-weight: bold;
}

.fs-i {
  text-decoration: underline;
}

.fs-10 {
  font-size: 10px;
}

.fs-15 {
  font-size: 15px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-10-i {
  font-size: 10px;
  font-style: italic;
}

.fs-15-i {
  font-size: 15px;
  font-style: italic;
}

.fs-20-i {
  font-size: 20px;
  font-style: italic;
}

.fs-25-i {
  font-size: 25px;
  font-style: italic;
}

.fs-10-u {
  font-size: 10px;
  text-decoration: underline;
}

.fs-15-u {
  font-size: 15px;
  text-decoration: underline;
}

.fs-20-u {
  font-size: 20px;
  text-decoration: underline;
}

.fs-25-u {
  font-size: 25px;
  text-decoration: underline;
}

.fs-10-b {
  font-size: 10px;
  font-weight: bold;
}

.fs-15-b {
  font-size: 15px;
  font-weight: bold;
}

.fs-20-b {
  font-size: 20px;
  font-weight: bold;
}

.fs-25-b {
  font-size: 25px;
  font-weight: bold;
}

.fs-10-2b {
  font-size: 10px;
  font-weight: bolder;
}

.fs-15-2b {
  font-size: 15px;
  font-weight: bolder;
}

.fs-20-2b {
  font-size: 20px;
  font-weight: bolder;
}

.fs-25-2b {
  font-size: 25px;
  font-weight: bolder;
}

/**
 * Height in pixels
 */
.h-5 {
  height: 5px;
}

.h-10 {
  height: 10px;
}

.h-15 {
  height: 15px;
}

.h-20 {
  height: 20px;
}

.h-30 {
  height: 30px;
}

/**
 * Width in pixels
 */
.w-5 {
  width: 5px;
}

.w-10 {
  width: 10px;
}

.w-15 {
  width: 15px;
}

.w-20 {
  width: 20px;
}

.w-30 {
  width: 30px;
}

/**
 * Height && Width in pixels
 */
.hw-5 {
  height: 5px;
  width: 5px;
}

.hw-10 {
  height: 10px;
  width: 10px;
}

.hw-15 {
  height: 15px;
  width: 15px;
}

.hw-20 {
  height: 20px;
  width: 20px;
}

.hw-30 {
  height: 30px;
  width: 30px;
}

/**
 * Height in ratio
 */
.hr-5 {
  height: 5%;
}

.hr-10 {
  height: 10%;
}

.hr-15 {
  height: 15%;
}

.hr-20 {
  height: 20%;
}

.hr-25 {
  height: 25%;
}

.hr-50 {
  height: 50%;
}

.hr-75 {
  height: 75%;
}

.hr-100 {
  height: 100%;
}

/**
 * Width in ratio
 */
.wr-5 {
  width: 5%;
}

.wr-10 {
  width: 10%;
}

.wr-15 {
  width: 15%;
}

.wr-20 {
  width: 20%;
}

.wr-25 {
  width: 25%;
}

.wr-50 {
  width: 50%;
}

.wr-75 {
  width: 75%;
}

.wr-100 {
  width: 100%;
}

/**
 * Height && Width in ratio
 */
.hw-5 {
  height: 5px;
  width: 5px;
}

.hw-10 {
  height: 10px;
  width: 10px;
}

.hw-15 {
  height: 15px;
  width: 15px;
}

.hw-20 {
  height: 20px;
  width: 20px;
}

.hwr-5 {
  height: 5%;
  width: 5%;
}

.hwr-10 {
  height: 10%;
  width: 10%;
}

.hwr-15 {
  height: 15%;
  width: 15%;
}

.hwr-20 {
  height: 20%;
  width: 20%;
}

.hwr-25 {
  height: 25%;
  width: 25%;
}

.hwr-50 {
  height: 50%;
  width: 50%;
}

.hwr-75 {
  height: 75%;
  width: 75%;
}

.hwr-100 {
  height: 100%;
  width: 100%;
}

/**
 * All Margin
 */
.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 50px;
}

.m-30 {
  margin: 50px;
}

.m-50 {
  margin: 50px;
}

/**
 * Top Margin
 */
.mt-5 {
  margin-top: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 50px;
}

.mt-30 {
  margin-top: 50px;
}

.mt-50 {
  margin-top: 50px;
}

/**
 * Right Margin
 */
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 50px;
}

.mr-30 {
  margin-right: 50px;
}

.mr-50 {
  margin-right: 50px;
}

/**
 * Bottom Margin
 */
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

/**
 * Left Margin
 */
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 50px;
}

.ml-30 {
  margin-left: 50px;
}

.ml-50 {
  margin-left: 50px;
}

/**
 * All Padding
 */
.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 50px;
}

.p-30 {
  padding: 50px;
}

.p-50 {
  padding: 50px;
}

/**
 * Top Padding
 */
.pt-5 {
  padding-top: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 50px;
}

.pt-30 {
  padding-top: 50px;
}

.pt-50 {
  padding-top: 50px;
}

/**
 * Right Padding
 */
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 50px;
}

.pr-30 {
  padding-right: 50px;
}

.pr-50 {
  padding-right: 50px;
}

/**
 * Bottom Padding
 */
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 50px;
}

.pb-30 {
  padding-bottom: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

/**
 * Left Padding
 */
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 50px;
}

.pl-30 {
  padding-left: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.center-text {
  text-align: center;
  vertical-align: middle;
}

.truncate-1l {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.truncate-2l {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-3l {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.c-pointer {
  cursor: pointer;
}

.space-filler {
  flex: 1 1 auto;
}

.new-line {
  word-wrap: break-word;
  white-space: pre-wrap;
}

@media print {
  .do-not-print, .do-not-print * {
    display: none !important;
  }

  #hubspot-messages-iframe-container {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  .page-break {
    page-break-before: always;
  }

  body, html, #wrapper {
    width: 100%;
  }

  img {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}
.print-only, .print-only * {
  display: none !important;
}

body, html {
  width: 100%;
  height: 100%;
}

.print-temporary-class {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: 100000;
}

.ql-editor .mention {
  background-color: orange !important;
}

.ql-mention-list-container {
  width: auto !important;
}