/**
 * All Margin
 */
.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 50px;
}

.m-30 {
  margin: 50px;
}

.m-50 {
  margin: 50px;
}

/**
 * Top Margin
 */
.mt-5 {
  margin-top: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 50px;
}

.mt-30 {
  margin-top: 50px;
}

.mt-50 {
  margin-top: 50px;
}

/**
 * Right Margin
 */
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 50px;
}

.mr-30 {
  margin-right: 50px;
}

.mr-50 {
  margin-right: 50px;
}

/**
 * Bottom Margin
 */
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

/**
 * Left Margin
 */
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 50px;
}

.ml-30 {
  margin-left: 50px;
}

.ml-50 {
  margin-left: 50px;
}
